/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider, useSelector } from 'react-redux';
import {
  createRoutesFromElements,
  createBrowserRouter,
  Route,
  RouterProvider,
  Outlet,
  Navigate,
  useParams,
} from 'react-router-dom';

import store from './store';
import theme from '../app/theme';
import { selectAuth } from './slice';

import PlayerTemplate from './views/PlayerTemplate';
import RegisterDisplay from './views/Register';

export const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useSelector(selectAuth);
  const { displayId: urlDisplayId } = useParams();

  if (!isAuthenticated)
    return urlDisplayId ? (
      <Navigate to={`/register/${urlDisplayId}`} replace />
    ) : (
      <Navigate to="/register" replace />
    );

  return children ? children : <Outlet />;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Outlet />}>
      <Route
        index
        element={
          <ProtectedRoute>
            <PlayerTemplate />
          </ProtectedRoute>
        }
      />
      <Route path="register">
        <Route index element={<RegisterDisplay />} />
        <Route path=":displayId" element={<RegisterDisplay />} />
      </Route>
      <Route path=":displayId" element={<ProtectedRoute />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <StrictMode>
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <RouterProvider router={router} />
      </ChakraProvider>
    </Provider>
  </StrictMode>
);
